import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setIsMobile, setReloadRefresh } from '../actions'
import Loader from '../components/Loader'
import { checkMobile } from '../helpers/appHelpers'
import { useComponentDidMount } from '../hooks/useComponentDidMount'
import { State } from '../reducers'
import { fetchChain } from '../services/chainService'

// NOTE Try to keep the strictly necessary in this component. It meant for fetchChain and its dependencies (store + isMobile)
const StartupProvider = (props: { children: React.ReactElement }): React.ReactElement | null => {
  const { isMobile } = useSelector((state: State) => state)
  const isMounted = useRef(false)
  const { data: networksConfig, isLoaded: networksConfigLoaded } = useSelector(
    (state: State) => state.networksConfig
  )

  const reduxDispatch = useDispatch()

  const handleWindowSizeChange = useCallback((): void => {
    const isMobileWidth = checkMobile()
    reduxDispatch(setIsMobile(isMobileWidth))
  }, [reduxDispatch])

  const init = useCallback(async (): Promise<void> => {
    try {
      await fetchChain()

      reduxDispatch(setReloadRefresh(false, false))
      isMounted.current = true
    } catch (error) {
      console.error('Error fetching chain at startup', error)
      reduxDispatch(setReloadRefresh(true, false))
    }
  }, [reduxDispatch])

  useEffect(() => {
    if (isMounted.current) {
      init()
    }
  }, [isMobile, init])

  useComponentDidMount(() => {
    init()

    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)

    return (): void => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  })

  if (!networksConfigLoaded || !networksConfig?.length || isMobile === null) {
    return <Loader isDashboard={false} />
  }

  return <>{props.children}</>
}

export default StartupProvider
