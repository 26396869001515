import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useParams } from 'react-router-dom'

import { OgStaticMetadata } from '../model'
import { generateStaticMetadata } from '../services/metadataService'

const TokenStaticMetadataLoader = (): React.ReactElement | null => {
  const [ogStaticMetadata, setStaticOgMetadata] = useState<OgStaticMetadata | undefined>()

  const { token }: { token: string; walletAddress: string; tradeId: string } = useParams()

  const updateMetadata = async (token: string): Promise<void> => {
    const metadata = await generateStaticMetadata(token)
    setStaticOgMetadata(metadata)
  }

  useEffect(() => {
    updateMetadata(token)
  }, [token])

  return (
    <>
      {ogStaticMetadata && (
        <Helmet>
          <meta name="twitter:card" content={ogStaticMetadata.twitterCard} />
          <meta name="twitter:image" content={ogStaticMetadata.image} />
          <meta property="og:image" content={ogStaticMetadata.image} />
          <meta property="og:image:width" content={ogStaticMetadata.imageWidth} />
          <meta property="og:image:height" content={ogStaticMetadata.imageHeight} />
          <meta property="og:url" content={ogStaticMetadata.url} />
        </Helmet>
      )}
      {!ogStaticMetadata && (
        <Helmet>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content="https://dex.guru/og.png" />
          <meta property="og:image" content="https://dex.guru/og.png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:url" content="https://dex.guru" />
        </Helmet>
      )}
    </>
  )
}

export default TokenStaticMetadataLoader
