import { useWeb3React } from '@telekomconsalting/core'
import * as sdk from '@telekomconsalting/dex-guru-internal-sdk'
import { AuthContext, AuthContextState, AuthWrapper } from '@telekomconsalting/react-dexguru-wallet'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setWalletCategory } from '../actions'
import { setProviderMenuOpen } from '../actions/uiActions'
import { useConnectors } from '../components/ConnectorsContext'
import { environment } from '../config/settings'
import { getSessionHeader } from '../helpers/ProxyApiFetch/sessionManager'
import { UserWalletCategory } from '../model'
import { State } from '../reducers'
import apiClient from '../services/ApiClient'
import apm from '../services/apmService'

export const useAuth = (): AuthContextState => useContext(AuthContext)

const AuthWrapperWithContext = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[]
}): React.ReactElement => {
  const web3React = useWeb3React()
  const networksConfig = useSelector((state: State) => state.networksConfig.data)
  const { isProviderMenuOpen } = useSelector((state: State) => state.ui)
  const reduxDispatch = useDispatch()
  const { walletConnectors, initWalletConnectors } = useConnectors()
  useEffect(() => {
    const initWalletCategory = async (): Promise<void> => {
      if (web3React.account) {
        const walletCategoryResponse = await apiClient.wallets.getWalletCategory(web3React.account)

        const walletCategory =
          UserWalletCategory[
            walletCategoryResponse.responseData?.category as keyof typeof UserWalletCategory
          ] || UserWalletCategory.Unknown

        reduxDispatch(setWalletCategory(walletCategory))
      } else {
        reduxDispatch(setWalletCategory(undefined))
      }
    }

    initWalletCategory()
  }, [web3React.account, reduxDispatch])

  const onWalletConnectionError = async (error: Error): Promise<void> => {
    apm?.captureError(error)
  }

  // eslint-disable-next-line no-console
  console.log('*** auth wrapper', web3React.account, web3React.chainId, web3React.connector)

  return (
    <AuthWrapper
      walletConnectors={walletConnectors}
      isOpenProviderMenu={isProviderMenuOpen}
      onOpenProviderMenu={(): void => {
        initWalletConnectors(networksConfig)
        reduxDispatch(setProviderMenuOpen(true))
      }}
      onCloseProviderMenu={(): void => {
        reduxDispatch(setProviderMenuOpen(false))
      }}
      networksConfig={networksConfig}
      dexGuruAPIV2Url={environment.getDexGuruAuthApiV2Url()}
      dexGuruAPIV3Url={environment.getDexGuruAuthApiV3Url()}
      sdkCheckAuth={sdk.auth.checkAuth}
      sdkHasToken={sdk.auth.hasToken}
      sdkRefreshToken={sdk.auth.refreshToken}
      onWalletConnectionError={onWalletConnectionError}
      web3React={web3React}
      headers={getSessionHeader()}>
      {children}
    </AuthWrapper>
  )
}

export default AuthWrapperWithContext
