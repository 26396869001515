import { ReactComponent as Cold } from 'app-images/icons/emoji/cold.svg'
import { ReactComponent as Reload } from 'app-images/icons/reload.svg'
import { OptionsObject, SnackbarAction, useSnackbar } from 'notistack'
import React, { FC, useCallback, useState } from 'react'

import Button from '../components/buttons/Button'
import { FREEZE_SNACKBAR_KEY, VERSION_SNACKBAR_KEY } from '../constants'
import SnackbarItem from './SnackbarItem'
export interface SnackBarWrapperContextProps {
  removeSnackbar: (key: string) => void
  showSnackbar: (key: string, cb?: () => void) => void
  openedSnackbar: string[]
}

export const SnackBarWrapperContext = React.createContext<Partial<SnackBarWrapperContextProps>>({})

const addSnackbar = (
  key: string,
  enqueueSnackbar: (message: string | React.ReactElement, options: OptionsObject) => void,
  message: string | React.ReactElement,
  actions?: () => SnackbarAction,
  icon?: React.ReactElement
): void => {
  enqueueSnackbar(<SnackbarItem icon={icon} message={message} actions={actions} />, {
    persist: true,
    action: actions ? actions() : null,
    key,
  })
}

const newVersionSnackbar = (
  enqueueSnackbar: (message: string | React.ReactElement, options: OptionsObject) => void,
  actions?: () => SnackbarAction
): void => {
  addSnackbar(
    VERSION_SNACKBAR_KEY,
    enqueueSnackbar,
    'A new version of this app is available',
    actions,
    <Reload />
  )
}

const staleMarketSnackbar = (
  enqueueSnackbar: (message: string | React.ReactElement, options: OptionsObject) => void,
  actions?: () => SnackbarAction
): void => {
  addSnackbar(
    FREEZE_SNACKBAR_KEY,
    enqueueSnackbar,
    `Stale market, the last transaction has been executed more than 7 days ago`,
    actions,
    <Cold />
  )
}

/*
  (΄◞ิ౪◟ิ‵ ) Give me all the snackbars, I am hungry!
*/
const SnackbarWrapper: FC = ({ children }) => {
  const [openedSnackbar, setOpenedSnackbar] = useState<string[]>([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const removeSnackbar = (key: string): void => {
    closeSnackbar(key)
    const snackbars = openedSnackbar.filter(
      (openedSnackbarKey: string) => openedSnackbarKey !== key
    )
    setOpenedSnackbar(snackbars)
  }

  const showSnackbar = useCallback(
    (key: string): void => {
      const okAction = (): React.ReactElement => {
        return (
          <Button
            className="button button--lg button--main"
            onClick={(): void => closeSnackbar(FREEZE_SNACKBAR_KEY)}
            id="button-close">
            OK
          </Button>
        )
      }

      const refreshAction = (): React.ReactElement => {
        return (
          <Button
            className="button button--lg button--main"
            onClick={(): void => {
              closeSnackbar(VERSION_SNACKBAR_KEY)
              window.location.reload()
            }}
            id="button-refresh">
            Refresh
          </Button>
        )
      }

      switch (key) {
        case VERSION_SNACKBAR_KEY: {
          newVersionSnackbar(enqueueSnackbar, refreshAction)
          break
        }
        case FREEZE_SNACKBAR_KEY: {
          staleMarketSnackbar(enqueueSnackbar, okAction)
          break
        }

        default:
          break
      }

      const openedSnackbarWithKey = [...openedSnackbar, key]
      setOpenedSnackbar(openedSnackbarWithKey)
    },
    [closeSnackbar, enqueueSnackbar, openedSnackbar]
  )

  return (
    <SnackBarWrapperContext.Provider
      value={{
        removeSnackbar,
        showSnackbar,
        openedSnackbar,
      }}>
      {children}
    </SnackBarWrapperContext.Provider>
  )
}

export default SnackbarWrapper
