import classNames from 'classnames'
import { SnackbarAction } from 'notistack'
import React from 'react'

interface SnackbarItemProps {
  message: string | React.ReactElement
  icon?: React.ReactElement
  actions?: () => SnackbarAction
  className?: string
}
const SnackbarItem = (props: SnackbarItemProps): React.ReactElement => {
  const renderActions = props.actions ? props.actions() : null
  return (
    <div className={classNames('notify-toast', { [`${props.className}`]: !!props.className })}>
      {props.icon && (
        <div className="notify-toast__icon">
          <span className="icon">{props.icon}</span>
        </div>
      )}
      <div className="notify-toast__message">{props.message}</div>
      {renderActions && <div className="notify-toast__action"> {renderActions}</div>}
    </div>
  )
}

export default SnackbarItem
